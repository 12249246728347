<template>
	<div class="p-grid">
            <div class="p-col-12 p-md-12 p-lg-12">
				<DataTable :value="$store.state.risk.detail" :scrollable="true" :scrollHeight="heigt_sc+'px'"  v-model:selection="selectedCustomers" 
					:filters="filters" class="p-datatable-sm" :paginator="false" editMode="cell" dataKey="id"  
					@cell-edit-init="Init" @cell-edit-complete="Save" v-model:editingRows="editingRows" v-model:expandedRows="expandedRows">
					<template #header>
						<div class="table-header">
							<div>
								<strong>{{$t('Responsible')}}:</strong>
								{{ $store.state.risk.data.responsible }}
							</div>
						</div>
					</template>
					<Column :header="$t('N°')" bodyStyle="width: 30px; background-color: #CECECE; text-align: center;" headerStyle="width: 30px; background-color: #CECECE; text-align: center;">
						<template #body="slotProps" >
							{{slotProps.index+1}}
						</template>
						<template #editor="slotProps">
							{{slotProps.index+1}}
						</template>
					</Column>
					<Column field="status" :header="$t('Status')" headerStyle="width: 130px">
						<template #body="{data}">
							{{ data['status_'+i18n.locale()] }}<i class="pi pi-pencil edit_tab" v-if="data.status_id==2"/>
						</template>
						<template #editor="{ data }">
							<div v-if="data.status_id==2">
								<div v-for="opc in $store.state.risk.basic.status.filter(val =>val.id==1 || val.id==2 || val.id==3)" :key="opc.key" class="p-ml-1 p-mr-1 p-mb-1">
									<RadioButton v-model="data.status" :inputId="opc.key" name="dynamic" :value="opc"/>
									<label :for="opc.key" class="p-ml-2 p-mr-2">{{ opc[i18n.locale()] }}</label>
								</div>
							</div>
							<div v-else>
								{{ data['status_'+i18n.locale()] }}
							</div>
						</template>
					</Column>
					<Column :expander="true" headerStyle="width: 30px" />
                    <Column field="description" :header="$t('Scenario')" headerStyle="width: 300px">
						<template #body="{data}">
							{{ data.description }}
						</template>
						<template #editor="{ data }">
							{{ data.description }}
						</template>
					</Column>
                    <Column field="likelihood" headerStyle="width: 200px" :header="$t('Likelihood')">
						<template #body="{data}">
							<Tag style="width: 30px; height: 30px; background-color: #CECECE">
								{{data.likelihood}}
							</Tag>
							{{ data['likelihood_'+i18n.locale()] }}<i class="pi pi-pencil edit_tab" v-if="data.status_id==2"/>
						</template>
						<template #editor="{ data }">
                           
							<div v-if="data.status_id==2">
								<div v-for="opc in $store.state.risk.basic.likelihood" :key="opc.key" class="p-ml-1 p-mr-1 p-mb-1">
									<RadioButton v-model="data.likelihood" :inputId="opc.key" name="dynamic" :value="opc"/>
									<label :for="opc.key" class="p-ml-2 p-mr-2">{{ opc[i18n.locale()] }}</label>
								</div>
							</div>
							<div v-else>
								<Tag style="width: 30px; height: 30px; background-color: #CECECE">
									{{data.likelihood.id}}
								</Tag>
								{{ data['likelihood_'+i18n.locale()] }}
							</div>
						</template>
					</Column>
                    <Column field="impact" :header="$t('Impact')" headerStyle="width: 200px">
						<template #body="{data}">
							<Tag style="width: 30px; height: 30px; background-color: #CECECE">
								{{data.impact}}
							</Tag>
							{{ data['impact_'+i18n.locale()] }}<i class="pi pi-pencil edit_tab" v-if="data.status_id==2"/>
						</template>
						<template #editor="{ data }">
							<div v-if="data.status_id==2">
								<div v-for="opc in $store.state.risk.basic.impact" :key="opc.key" class="p-ml-1 p-mr-1 p-mb-1">
									<RadioButton v-model="data.impact" :inputId="opc.key" name="dynamic" :value="opc"/>
									<label :for="opc.key" class="p-ml-2 p-mr-2">{{ opc[i18n.locale()] }}</label>
								</div>
							</div>
							<div v-else>
								<Tag style="width: 30px; height: 30px; background-color: #CECECE">
									{{data.impact.id}}
								</Tag>
								{{ data['impact_'+i18n.locale()] }}
							</div>
						</template>
					</Column>
                    <Column field="inherent" :header="$t('Inherent risk')" headerStyle="width: 200px">
						<template #body="{data}">
                            <Tag :style="'width: 30px; height: 30px; background-color: #'+data.score_color">
								{{data.value}}
							</Tag>
							{{ data['score_'+i18n.locale()] }}
						</template>
						<template #editor="{ data }">
                            <Tag :style="'width: 30px; height: 30px; background-color: #'+data.score_color">
								{{data.value}}
							</Tag>
							{{ data['score_'+i18n.locale()] }}
						</template>
					</Column>
                    <Column field="appetite" :header="$t('Risk appetite')" headerStyle="width: 200px">
						<template #body="{data}">
							<Tag :style="'width: 30px; height: 30px; background-color: #'+data.app_color">
								{{ data['app_'+i18n.locale()] }}
							</Tag>
							{{ data['appetite_'+i18n.locale()] }}<i class="pi pi-pencil edit_tab" v-if="data.status_id==2"/>
						</template>
						<template #editor="{ data }">
							<div v-if="data.status_id==2">
								<div v-for="opc in $store.state.risk.basic.appetite" :key="opc.key" class="p-ml-1 p-mr-1 p-mb-1">
									<RadioButton v-model="data.appetite" :inputId="opc.key" name="dynamic" :value="opc"/>
									<label :for="opc.key" class="p-ml-2 p-mr-2">{{ opc[i18n.locale()] }}</label>
								</div>
							</div>
							<div v-else>
								<Tag :style="'width: 30px; height: 30px; background-color: #'+data.app_color">
									{{ data['app_'+i18n.locale()] }}
								</Tag>
								{{ data['appetite_'+i18n.locale()] }}
							</div>
						</template>
					</Column>
					<template #expansion="{data}">
						<Basic :data="data" :step="1"/>
					</template>	
				</DataTable>
            </div>
	</div>
</template>

<script>
	import { useI18nPlugin } from '@unify/vuex-i18n';
	import Token from "uuid-token-generator";
	import { defineAsyncComponent } from "@vue/runtime-core";

	export default {
		components: {
			Basic: defineAsyncComponent(() => import('./Basic.vue')),
        },
		props: {
			step: {
				type: Number,
				default: 0,
			},
		},
		data() {
			return {
				i18n: null,
				url: null,
				img: null,
				porc: 0,
				heigt_sc: 0,
				editingRows: [],
				expandedRows: [],
				product: null,
				error:null,
			}
		},
		productService: null,
		created() {
			this.i18n = useI18nPlugin();
			this.url = process.env.VUE_APP_API;
			const tokgen = new Token(256);
			this.img = tokgen.generate();
			if(this.$store.state.screen<=728){
				this.heigt_sc =  380;
			} else {
				this.heigt_sc =  500;
			}
		},
		mounted() {
			
		},
		methods: {
			Init(event) {
				let { data, index, field } = event;
				this.$store.state.risk.editing.push(field+index);
				switch (field) {
					case 'status':
						this.$store.state.risk.detail[index][field] = this.$store.state.risk.basic.status.filter(val => val.id == data.status)[0];
						break;
					case 'likelihood':
						this.$store.state.risk.detail[index][field] = this.$store.state.risk.basic.likelihood.filter(val => val.id == data.likelihood)[0];
						break;
					case 'impact':
						this.$store.state.risk.detail[index][field] = this.$store.state.risk.basic.impact.filter(val => val.id == data.impact)[0];
						break;
					case 'appetite':
						this.$store.state.risk.detail[index][field] = this.$store.state.risk.basic.appetite.filter(val => val.id == data.appetite)[0];
						break;
				}
			},
			Save(event) {
				let { data, index, field } = event;
				this.$store.state.risk.editing = this.$store.state.risk.editing.filter(val => val != field+index);
				switch (field) {
					case 'status':
						this.$store.state.risk.detail[index].status_en = data.status.en;
						this.$store.state.risk.detail[index].status_es = data.status.es;
						this.$store.state.risk.detail[index].status = data.status.id;
						break;
					case 'likelihood':
						this.$store.state.risk.detail[index].likelihood_en = data.likelihood.en;
						this.$store.state.risk.detail[index].likelihood_es = data.likelihood.es;
						this.$store.state.risk.detail[index].likelihood = data.likelihood.id;
						break;
					case 'impact':
						this.$store.state.risk.detail[index].impact_en = data.impact.en;
						this.$store.state.risk.detail[index].impact_es = data.impact.es;
						this.$store.state.risk.detail[index].impact = data.impact.id;
						break;
					case 'appetite':
						this.$store.state.risk.detail[index].appetite_en = data.appetite.en;
						this.$store.state.risk.detail[index].appetite_es = data.appetite.es;
						this.$store.state.risk.detail[index].app_en = data.appetite.app_en;
						this.$store.state.risk.detail[index].app_es = data.appetite.app_es;
						this.$store.state.risk.detail[index].app_color = data.appetite.app_color;						
						this.$store.state.risk.detail[index].appetite = data.appetite.id;
						break;
				}
			},
		}
	}
</script>
<style scoped lang="scss">
.cheap {
	background-color: #54a90a !important;
	color: #ffffff !important;
}

.edit_tab{
	padding: 10px 10px 10px 10px;
	font-size: 10px;
	color: red;
}

.ttitle{
    font-family: 'Rajdhani', sans-serif;
    font-size: calc(0.7rem + 1vw);
    font-weight: 600;
    color: #2b3b80;
    padding: 1em 0em 1em 0.5em;
}
.text-title{
    font-family: 'Rajdhani', sans-serif;
    font-size: calc(0.7rem + 1vw);
    font-weight: 600;
    color: #2b3b80;
}

.p-datatable .p-column-filter {
	display: none;
}

.table-header {
	display: flex;
	justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

.customer-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-qualified {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-unqualified {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-negotiation {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.status-new {
		background: #B3E5FC;
		color: #23547B;
	}

	&.status-renewal {
		background: #ECCFFF;
		color: #694382;
	}

	&.status-proposal {
		background: #FFD8B2;
		color: #805B36;
	}
}

@media (max-width: 640px) {

	
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
	padding: 1rem;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.order-delivered {
		background: #C8E6C9;
		color: #256029;
	}

	&.order-cancelled {
		background: #FFCDD2;
		color: #C63737;
	}

	&.order-pending {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.order-returned {
		background: #ECCFFF;
		color: #694382;
	}
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}


				}
			}
		}
	}
}
</style>